import { Link } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import React from 'react';
import { Header, Layout, LinkList, SEO } from '../components';

import PageProps from '../models/PageProps';

const AllTagsPage = ({ pathContext }: PageProps) => {
  const { tags } = pathContext || {};

  return (
    <Layout>
      <SEO isPost={false} titlePrefix={'Blog | '} />
      <Header title='All Tags' icon='document-edit' subHeading='Engineering & UX Blog' />
      <LinkList>
        {(tags || []).map((category, idx) => (
          <h2 key={idx}><Link to={`/tags/${kebabCase(category)}`}>{category}</Link></h2>
        ))}
      </LinkList>
    </Layout>
  );
};

export default AllTagsPage;
